*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
.main-content {
  position: relative;
  /* color: #fff; */
  padding-bottom: 30px;

}
.desktop-content {
  display: block;
}
.space {
  padding-top: 4%;
}
.Text-container {
  text-align: center;
}
.row-head {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.badges {
  padding-top: 10px;
  align-items: center;
  flex-wrap: wrap;
  font-size: 11px;
  line-height: 32px;
  color: #fff;
  justify-content: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.table-row{
  padding: 0px;
  height: 120px;
  display: flex;
  border-radius: 7px;
  background: 00;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
}
.cell-logo{
 width: 21%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
img{
  vertical-align: middle;
  border-style: none;
}
.link-upi{
  font-size: 11px;
  font-weight: 300;
  line-height: 1.64;
  margin-top: 2px;
  cursor: pointer;

}

.cell-bonus p{
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}
.cell-bonus{
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.cell-rating{
  width: 15% !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.cell-score{
  width: 15%;
  font-size: 48px;
  font-weight: 400;
  line-height: 0.63;
  color: #000;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.bonus-btn{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.cell {
  display: flex;
}
.get-bonus-btn{
  height: 42px;
  width: 80%;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(28, 44, 64, 0.17);
  display: flex;
  justify-content: center;
  align-items: center;
}
.visit{
  font-size: 13px;
  font-weight: 300;
  line-height: 1.46;
  color: #000;
  margin-top: 10px;
  text-decoration: none;
}
.content{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width:767px){
  .hide-mobile{
      display:none
  }
}
@media (min-width:768px){
  .hide-desktop{
      display:none
  }
}
.list-mobile .row-casino{
  /* height: 200px !important; */
  display: flex;
  /* width: 100%; */
  background-color: #fff;
  /* margin-top: 10px; */
  box-shadow:  0 1px 5px 0 #989faa;
  flex-flow: column;
  margin: 5px 30px;
  padding: 15px;

}
.left-part{
  width: 100%;
  /* background-color: yellowgreen; */
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.right-part{
  width: 100%;
  /* background-color: #fff; */
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* padding: 0 5px; */
}
.right-part .legal{
  font-size: 11px;
  /* margin: 12px 0; */
  margin-bottom: 10px;
}
.score-casino{
  font-size: 40px;
  font-weight: 400;

}
@media (min-width: 1200px) {
  .Text-container {
    width: 1000px;
  }
  .content {
    max-width: 1114px;
  }
}
@media (max-width: 767px){
  .desktop-content{
    display: none;
  }
  .ico-item{
    width: 40px !important;
  }
}
.main-container{
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0 20px rgba(160, 166, 168, 0.35);
  padding: 30px 20px;
  text-align: left;
}
.ico-header-list{
  padding: 0;
  text-align: center;
  list-style: none;
}
.ico-item{
  display: inline-block;
  width: 60px;
  margin: 20px;
}
.ico-header-list .ico-item img {
  width: 100%;
}
.dl-list .table-row .cell-bonus:after,
.dl-list .table-row .cell.min-deposit:after,
.dl-list .table-row .cell-rating:after,
.dl-list .table-row .cell-score:after {
    content: "";
    border-left: 1px solid #e6e8eb;
    position: absolute;
    height: 65%;
    right: 0;
    top: 18%;
}

.cell {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 11px;
}
.logo{
  width: 20%;
}
.bonus{
  width: 27.50%;
}
.ratinghead{
  width: 14%;
}
.features{
  width: 14%;
}
.get-bonus{
  width: 24%;
}
.big-bonus1{
  line-height: .88;
  font-weight: 500;
  padding: 2px 0px;
  font-size: 34px;
}